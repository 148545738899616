html, body{
  min-width: 100%!important;
}
@media screen and (max-width:430px) {
  .pd-0{
    padding: 0!important;
  }
}
::-webkit-scrollbar {
  width: 0px !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

.gray__scroller::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  cursor: pointer;
}

.gray__scroller::-webkit-scrollbar-track {
  background-color: transparent;
}

.gray__scroller::-webkit-scrollbar-thumb {
  background-color: #a5bfec;
  border-radius: 20px;
}

.sidePadding {
  padding: 3rem;
}

.lightGray__bg {
  background-color: #edf3ff;
}

.loader__ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue__bg {
  background-color: #3880ff;
  color: #fff;
}
.blue__bg:hover {
  background-color: #3880ff!important;
  color: #fff;
}

.dark__blue__bg {
  background-color: #2b3590;
  color: #fff;
}

.commom_btn_height {
  height: 3.5rem;
  padding-inline: 3rem;
}

.text__blue {
  color: #3880ff;
}

.large__font {
  font-size: 1.75rem;
}

.semi__large__font {
  font-size: 1.0125rem;
}

.bold {
  font-weight: bold;
}

.b500 {
  font-weight: 500;
}

.light__black {
  color: #202020;
}

.br10 {
  border-radius: 10px;
}

.br20 {
  border-radius: 20px;
}

.br5 {
  border-radius: 5px;
}

.btn__height__high {
  height: 3.5rem;
}

.btn__height__low {
  height: 2.5rem;
}

.box__shadow {
  box-shadow: 0px 5px 10px #3880ff1a;
  padding: 0.2rem;
  border-radius: 100%;
}

.card__shadow {
  box-shadow: 0px 5px 20px #0000000d;
}

.button__box__shadow {
  box-shadow: 0px 3px 6px #0000001a;
}

.input__box__ {
  box-shadow: 0px 5px 10px #2020200d;
  border: none;
  min-height: 2.75rem;
  border: none;
  background-color: #fff;
}

.input__box__::placeholder {
  color: #838080;
}

.input__box__ .ant-select-selection-placeholder {
  color: #838080;
  display: flex;
  align-items: center;
}

.input__box__ .ant-picker-input input::placeholder {
  color: #838080;
}

.signup__container {
  display: grid;
  place-items: center;
  width: max-content;
  margin: auto;
  padding: 1rem 2rem;
  min-width: 33%;
}

.signup__success__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  margin: auto;
  padding: 1rem 2rem;
  min-width: 33%;
}

.upload__img__container {
  height: 80px;
  width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload__img__container > img {
  height: 100%;
  width: 100%;
}

.ant-select-selector {
  height: 2.75rem !important;
  border: none;
  align-items: center;
}

.btn__container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.min__height {
  height: calc(87vh - 60px);
}

.doctorList {
  background-color: #edf3ff;
  display: flex !important;
  flex-direction: column;
  gap: 5px;
  margin-right: 10px;
  padding: 12px;
  border-radius: 7px;
}

.doctorList > img {
  height: 160px;
  width: 100%;
  border-radius: 10px;
}

.timeSlot__container {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 1.25rem;
  background-color: #edf3ff;
}

.timeSlot__btn__container {
  display: flex;
  gap: 20px;
  max-width: 550px;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.doctor__list__container {
  display: flex;
  gap: 5px;
  max-width: 600px;
  margin-bottom: 2rem;
  overflow-x: auto;
  min-height: 250px;
}

.date__selector {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.doc__list__scroll {
  width: 600px !important;
}

.active-border {
  background: #ccdfff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.pdb {
  padding-bottom: 15px;
}
.active-text {
  color: #3880ff !important;
  font-weight: bold;
}

/* *******************************Plaid payments styling***************** */

@media screen and (max-width: 991px) {

  .doc__list__scroll {
    width: 400px !important;
  }

  .timeSlot__btn__container {
    gap: 20px;
    max-width: 400px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .signup__container {
    width: fit-content;
    padding: 1rem !important;
  }

  .doc__list__scroll {
    width: 320px !important;
  }

  .timeSlot__container {
    padding: 1rem !important;
  }

  .timeSlot__btn__container {
    display: flex;
    gap: 20px;
    max-width: 320px;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  .btn__container {
    flex-wrap: wrap;
  }
}
.agora_video_player {
  object-fit: contain !important;
}

.img_content {
  /* border: 1px solid #fff; */
  /* background: #ffffff48; */
  /* padding: 0.5rem; */
  position: absolute;
  margin: 2.5rem;
  z-index: 999;
}

.img_content div{
  width: 90%;
}

.banner-title-text {
  /* border: 1px solid #fff; */
  background:#201f1f47;
  font: 800 2rem "Nunito", sans-serif;
  margin-bottom: 1rem;
  padding: 0 0.8rem;
  font-style: italic;
}

.banner-sub-title-text {
  background: #201f1f47;
  /* border: 1px solid #fff; */
  font: 800 1rem "Nunito", sans-serif;
  padding: 0.2rem 0.5rem;
  font-style: italic;
}

/* *******************************Plaid payments styling***************** */
.bank_list {
  border: 1px solid #000;
  padding: 0.8rem;
  border-radius: 5px;
}

.image_manage {
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  position: relative;
}

@media screen and (min-width: 1800px) {
  .image_manage {
    height: 550px;
  }
  .squareImageDiv {
    height: 550px;
  }
}

.ant-btn:hover{
  background: transparent!important;
  color: black !important;
}
