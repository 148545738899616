@media screen and (min-width: 1800px) {
    .image_manage {
      height: 550px;
    }
    .squareImageDiv {
      height: 550px;
    }
  }
  @media screen and (max-width:810px) {
    .sideGap{
        padding: 0 15px!important;
    }
    .dbtn{
        display: none;
    }
    .image_manage{
        height: 200px!important;
    }
    .ImageCardCrousel div{
        height: 300px!important;
    }
    .level-img{
        height: 210px!important;
    }
    .active-border {
        width: 100%;
      }
      .img_content{
        display: none;
      }
      .squareImageDiv{
        height: 360px;
      }
      .dm-none{
        display: none;
      }
      .search-input{
        width: 100%;
      }
      .pd-0{
        padding: 0!important;
      }
}

@media screen and (max-width:430px) {
      .dm-none{
        display: none;
      }
      .pd-0{
        padding: 0!important;
      }
}
  
@media screen and (max-width:991px) {
    .bigImageDiv img {
        height:400px !important;
    }
    .marginForResponsive{
        margin-top: 30px;
    }
    .textMainDiv {
        padding: 15px;
        text-align: center;
    }
    .downloadBtns {
        text-align: center;
    }
    .footer_LastPatti img{
        width: 10%;
    }
    .paddingWalaDiv{
        padding-left: 0px;
    }
    .playBtn{
        top: 34% !important;
        height: 52px !important;
        left: 40% !important;    
    }
    .pauseBtn{
        top: 34% !important;
        height: 52px;
        left: 40%;
    }
    .videoListBack video {
        height: 95px !important;
    }
  
}

@media screen and (max-width:500px) {
    .videoListBack{
        margin-right: 0px !important;
    }
    .bigImageDiv img {
        height:215px !important;
    }
}